html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* clear deafult stylings for browser consistencies */
@tailwind base;

/* define the default styles here */
@layer base {
  h1 {
    @apply text-xl md:text-2xl mb-1 mt-3 font-bold;
  }
  h2 {
    @apply text-lg md:text-xl mb-1 mt-2 font-semibold;
  }
  h3 {
    @apply text-base md:text-lg mb-1 mt-2;
  }
  p {
    @apply text-sm md:text-base my-1 text-gray-800;
  }

  table {
    @apply table p-4 bg-white shadow rounded-lg;
  }
  th {
    @apply border-b-2 font-normal text-gray-900 text-left;
  }
  td {
    @apply text-gray-700;
  }
  tr {
    @apply border-b-2;
  }
}

@tailwind components;
@tailwind utilities;

/* HACK There is no better way to center the little dropdown triangle on our narrow button */
.custom-narrow-dropdown button.dropdown-toggle::after {
  vertical-align: 0.15em;
}
.custom-date-filter-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
  background-size: 8px 10px;
  background-repeat: no-repeat;
  background-position: right 5px bottom 50%;
  border-radius: 0.25rem; /*rounded border */
  border: 1px solid #34495e;
  padding: 5px 18px 5px 5px;
  color: black;
  text-align: center;
  font-size: 1rem;
  background-color: white;
  outline: none;
  width: 220px;
}

.custom-date-filter-input:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-date-filter-input::placeholder {
  color: black;
}

.custom-date-filter-input:hover {
  cursor: pointer;
}

/* TODO Emergency HACK(Chaoran): @tailwind base; is resetting the background colors of buttons of react-bootstrap */
/* See #681 */
.btn-primary {
  background-color: #007bff;
}

.btn-secondary {
  background-color: #6c757d;
}

.btn-info {
  background-color: #17a2b8;
}
/* HACK END */
