.st0 {
  fill: whitesmoke;
  stroke: #000000;
  stroke-miterlimit: 10;
}

.st1 {
  font-family: 'MyriadPro-Regular';
  fill: whitesmoke;
}

.st2 {
  font-size: 85px;
  fill: whitesmoke;
}

.st0_4 {
  display: none;
  fill: whitesmoke;
}

.st1_4 {
  fill: whitesmoke;
  display: inline;
  fill: none;
  stroke: #000000;
  stroke-miterlimit: 10;
}

.st3 {
  font-family: 'MyriadPro-Regular';
  fill: whitesmoke;
}

.st4 {
  font-size: 35px;
  fill: whitesmoke;
}

.st5 {
  font-size: 20px;
  fill: whitesmoke;
}

.st0:hover .st1:hover .st2:hover .st3:hover .st4:hover .st5:hover .st2_4:hover .st1_4:hover .st0_4:hover {
  fill: rgb(238, 135, 17);
}

.svgPlot {
  enable-background: new 0 0 910 577;
  margin: auto;
}

.svgPlot3 {
  enable-background: new 0 0 1178.62 1127.39;
  margin: auto;
}

.svgPlot4 {
  enable-background: new 0 0 1366 768;
  margin: auto;
}

.svgPlot3 {
  enable-background: new 0 0 910 577;
  margin: auto;
}

.svgPlotNumber {
  white-space: pre;
  cursor: pointer;
  fill: black;
}
.svgPlotNumber:hover {
  fill: royalblue;
}

.svgPlotText {
  white-space: pre;
  fill: blue;
  font-family: Arial, sans-serif;
  font-weight: bolder;
  font-size: 35px;
}

.hovered {
  fill: rgb(238, 135, 17);
  font-weight: bold;
}

.hoveredCircle {
  color: red;
  fill: rgb(238, 135, 17);
}
